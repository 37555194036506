import React, { useState, useEffect, useCallback } from 'react';
import './PopupDevice.css';

import EmojiPicker from 'emoji-picker-react';
import PopupText from './PopupText';
import PopupTags from './PopupTags';

const Popupzone = ({ 
    name,             
    devices,
    datas,
}) => {
    

    return (
        <></>
    );
};

export default Popupzone;
