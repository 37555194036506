import React, { useState, useEffect } from 'react';
import './CardTemp.css';
import Popup from './Popuptemp.js';

const CardZone = ({ 
  name, 
  uid, 
  token, 
  devices, 
  datas,  
  setArlampopup,
  arlampopup
}) => {
  const [temperatureValues, setTemperatureValues] = useState({});
  const [alarmActive, setAlarmActive] = useState(false);
  const [warningActive, setWarningActive] = useState(false);
  const [humidityValues, setHumidityValues] = useState({});
  const [validDevices, setValidDevices] = useState([]);
  const [popupActive, setPopupActive] = useState(false);

  

  const [alarmAcknowledged1, setAlarmAcknowledged1] = useState(false); 
  const [alarmAcknowledged2, setAlarmAcknowledged2] = useState(false); 
  const [nanCounts1, setNanCounts1] = useState(0); 
  const [nanCounts2, setNanCounts2] = useState(0); 
 
  
  // Funktion zum Quittieren des Alarms
  const handleAlarmAcknowledge = () => {

    toggleAlarm(); 
  };

  const toggleAlarm = () => {
    setAlarmActive(!alarmActive);
  };

  // Funktion, um die Warnung zu toggeln
  const toggleWarning = () => {
    setNanCounts2(0);
    setNanCounts1(0);
    setWarningActive(!warningActive);
  };
  const loadPopup = () => {
    setPopupActive(true);
  };

  const unloadPopup = () => {
      setPopupActive(false);
  };
  useEffect(() => {
    let newAlarms = [];
    let grenztime = 15;
    let grenzvalue = 8;
    devices.forEach((device) => {
      if (device.deviceUID === '00.0000042B45FC') {
        // Grade 1 Tempern & RGA
        if (!isNaN(datas['00.00000510CC30'].data[0][0])) {
          if (datas['00.00000510CC30'].data[0][0] < grenzvalue && !alarmAcknowledged1) {
            setAlarmAcknowledged1(true);
            setAlarmActive(true);
  
            const alarmText = <span style={{ color: 'red' }}>Grade 1 Tempern & RGA Alarm</span>;
  
            if (!arlampopup.includes(alarmText)) {
              newAlarms.push(alarmText);
            }
          } else if (datas['00.00000510CC30'].data[0][0] >= grenzvalue && alarmAcknowledged1) {
            setAlarmAcknowledged1(false);
          }
          //setNanCounts1(0)
        }else{
          /*setNanCounts1(nanCounts1 + 1)
          (nanCounts1 > 15 && !warningActive) && (<>{setWarningActive(true)}</>)*/
        }
      }
  
      if (device.deviceUID === '00.0000042B4B84') {
        // Grade 1 Verpacken
        if (!isNaN(datas['00.00000510CC30'].data[1][0])) {
          if (datas['00.00000510CC30'].data[1][0] < grenzvalue && !alarmAcknowledged2) {
            setAlarmAcknowledged2(true);
            setAlarmActive(true);
  
            const alarmText = <span style={{ color: 'red' }}>Grade 1 Verpacken Alarm</span>;
  
            if (!arlampopup.includes(alarmText)) {
              newAlarms.push(alarmText);
            }
          } else if (datas['00.00000510CC30'].data[1][0] >= grenzvalue && alarmAcknowledged2) {
            setAlarmAcknowledged2(false);
          }
          //setNanCounts2(0)
        }else{
          /*setNanCounts2(nanCounts2 + 1)
          (nanCounts2 > 15 && !warningActive) && (<>{setWarningActive(true)}</>)*/
        }
      }
  
      if (device.deviceUID === '00.0000042B4B93') {
        // Grade 2 Tempern & RGA
        if (!isNaN(datas['00.00000510A86A'].data[0][0])) {
          if (datas['00.00000510A86A'].data[0][0] < grenzvalue && !alarmAcknowledged1) {
            setAlarmAcknowledged1(true);
            setAlarmActive(true);
  
            const alarmText = <span style={{ color: 'red' }}>Grade 2 Tempern & RGA Alarm</span>;
  
            if (!arlampopup.includes(alarmText)) {
              newAlarms.push(alarmText);
            }
          } else if (datas['00.00000510A86A'].data[0][0] >= grenzvalue && alarmAcknowledged1) {
            setAlarmAcknowledged1(false);
          }
          //setNanCounts1(0)
        }else{
          /*setNanCounts1(nanCounts1 + 1)
          (nanCounts1 > 15 && !warningActive) && (<>{setWarningActive(true)}</>)*/
        }
      }
  
      if (device.deviceUID === '00.0000042B4BBE') {
        // Grade 2 Verpacken
        if (!isNaN(datas['00.00000510A86A'].data[1][0])) {
          if (datas['00.00000510A86A'].data[1][0] < grenzvalue && !alarmAcknowledged2) {
            setAlarmAcknowledged2(true);
            setAlarmActive(true);
  
            const alarmText = <span style={{ color: 'red' }}>Grade 2 Verpacken Alarm</span>;
  
            if (!arlampopup.includes(alarmText)) {
              newAlarms.push(alarmText);
            }
          } else if (datas['00.00000510A86A'].data[1][0] >= grenzvalue && alarmAcknowledged2) {
            setAlarmAcknowledged2(false);
          }
          //setNanCounts2(0)
        }else{
          /*setNanCounts2(nanCounts2 + 1)
          (nanCounts2 > 15 && !warningActive) && (<>{setWarningActive(true)}</>)*/
        }
      }
    });
  
    if (newAlarms.length > 0) {
      setArlampopup(prev => [...prev, ...newAlarms.filter(alarm => !prev.includes(alarm))]);
    }
  }, [datas, devices]); 
  useEffect(() => {
    const newTemperatureValues = {};
    const newHumidityValues = {};
    const validDevicesList = [];

    devices.forEach((device) => {
      const deviceData = datas[device.deviceUID];

      if (deviceData) {
        const tempIndex = device.unitNames.indexOf('Temperatur');
        const humidityIndex = device.unitNames.indexOf('Feuchtigkeit');

        if (tempIndex !== -1 && deviceData.data[tempIndex]) {
          newTemperatureValues[device.deviceUID] = parseFloat(deviceData.data[tempIndex][0]).toFixed(1); // Runden auf 1 Nachkommastelle
          newHumidityValues[device.deviceUID] = humidityIndex !== -1 && deviceData.data[humidityIndex]
            ? parseFloat(deviceData.data[humidityIndex][0]).toFixed(1) // Feuchtigkeitswert
            : null;
          validDevicesList.push(device); // Nur Geräte mit Temperaturwerten werden hier aufgenommen
        }
      }
    });

    setTemperatureValues(newTemperatureValues);
    setHumidityValues(newHumidityValues);
    setValidDevices(validDevicesList); // Nur gültige Geräte mit Temperaturwerten
  }, [devices, datas]);

  // Funktion zum Aufteilen der Geräte in Gruppen von 4
  const splitIntoGroups = (devicesList) => {
    const groups = [];
    for (let i = 0; i < devicesList.length; i += 4) {
      groups.push(devicesList.slice(i, i + 4));
    }
    return groups;
  };

  // Aufteilen der Geräte in Gruppen von maximal 4 Geräten pro Zeile
  const deviceGroups = splitIntoGroups(validDevices);

  return (
    <div>
      <div className="Cardzone">
        <div className="Cardzone-Header">Zone {name}</div> {/*onClick={loadPopup}*/}
        <div className="Cardzone-field">
          {/* Für jede Gruppe von Geräten eine neue Tabelle */}
          {deviceGroups.map((group, index) => (
            <table key={index} className="data-table">
              <thead>
                <tr>
                  {group.map((device) => (
                    <th key={device.deviceUID}>{device.deviceName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Zeile für Temperatur */}
                <tr>
                  {group.map((device) => (
                    <td key={device.deviceUID}>
                      {temperatureValues[device.deviceUID]
                        ? `${temperatureValues[device.deviceUID]} °C`
                        : 'unset'}
                    </td>
                  ))}
                </tr>

                {/* Zeile für Feuchtigkeit */}
                <tr>
                  {group.map((device) => (
                    <td key={device.deviceUID}>
                      {humidityValues[device.deviceUID]
                        ? `${humidityValues[device.deviceUID]} %rF`
                        : 'unset'}
                    </td>
                  ))}
             </tr>
                {/* Überprüfe die deviceUID und füge eine zusätzliche Zeile hinzu */}
                <tr>
                  {group.map((device) => (
                    <td key={device.deviceUID}>
                      {device.deviceUID === '00.0000042B45FC' && (
                        <div>
                          {/*  Grade 1 Tempern & RGA */}
                          {isNaN(datas['00.00000510CC30'].data[0][0]) ?
                          ( 
                          <>
                            'NaN'
                          </>
                          ):(
                            <>
                            {(parseFloat(datas['00.00000510CC30'].data[0][0]) * 2.5).toFixed(1) }                      
                            </>
                          ) }
                          {' %O2'}
                        </div>
                      )}
                     {device.deviceUID === '00.0000042B4B84' && (
                      <div>
                        {/* Grade 1 Verpacken */}
                          {isNaN(datas['00.00000510CC30'].data[1][0]) ?
                          ( 
                          <>
                            'NaN'
                          </>
                          ):(
                            <>
                            {(parseFloat(datas['00.00000510CC30'].data[1][0]) * 2.5).toFixed(1) }                              
                            </>
                          ) }
                          {' %O2'}
                        </div>
                    )}

                    {device.deviceUID === '00.0000042B4B93' && (
                      <div>
                        {/* Grade 2 Tempern & RGA */}
                      {isNaN(datas['00.00000510A86A'].data[0][0]) ?
                          ( 
                          <>
                            'NaN'
                          </>
                          ):(
                            <>    
                            {(parseFloat(datas['00.00000510A86A'].data[0][0]) * 2.5).toFixed(1) }                         
                            </>
                          ) }
                          
                          {' %O2'}
                        </div>
                    )}

                    {device.deviceUID === '00.0000042B4BBE' && (
                      <div>
                        {/* Grade 2 Verpacken */}
                          {isNaN(datas['00.00000510A86A'].data[1][0]) ?
                          ( 
                          <>
                            'NaN'
                          </>
                          ):(
                            <>
                            {(parseFloat(datas['00.00000510A86A'].data[1][0]) * 2.5).toFixed(1) }   
                            </>
                          ) }
                          {' %O2'}
                        </div>
                    )}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          ))}
        </div>

        {/* Alarm und Warnung */}
        <div className="bottom-section">
          {/* Alarm */}
          {alarmActive ? (
          <div className="data-point-ar" onClick={handleAlarmAcknowledge}>
            <div className={`data-value-ar blink alarm`}>
              Alarm Quittieren
            </div>
          </div>
          ):( 
          <div className="data-point-ar" onClick={handleAlarmAcknowledge}>
            <div className={`data-value-ar alarm`} style={{border: 'none'}}>
              &nbsp;
            </div>
          </div>
          )}
          {/* Warnung */}
         {warningActive && (
         <div className="data-point-ar" onClick={ toggleWarning }>
            <div className={`data-value-ar blink warning`}>
               Warnung Quittieren
            </div>
          </div>
          )}
        </div>
      </div>
      {popupActive && 
                <Popup 
                    type="zone"                     
                    name={name} 
                    devices={devices}  // Du kannst hier zusätzliche Daten übergeben
                    deactivate={unloadPopup} 
                    datas={datas}
                />
            }
    </div>
  );
};

export default CardZone;
