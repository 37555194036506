import React, { useRef, useEffect, useState } from 'react';
import './Popup.css';

import Popupzone from './Popupzone';



const Popup = ({ 
    type, 
    name, 
    deactivate, 
    devices,
    datas
   
}) => {
    const popupRef = useRef(null);

    useEffect(() => {
        console.log("Popup Mounted");

        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                deactivate();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            console.log("Popup Unmounted");
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [deactivate]);

    const getPopupContent = () => {
        switch (type) {
            case "zone":
                return <Popupzone 
                    name={name}                  
                    devices={devices}
                    datas={datas} 
                    />;           
            default:
                return null;
        }
    };

    return (
        <div className="Popup" ref={popupRef}>
            <div className="Popup-Header">
                <div className="button Popup-closeButton" onClick={deactivate}>
                    <div className="icon-close"></div>
                </div>
                {name}
            </div>

            {getPopupContent()}
        </div>
    );
};

export default Popup;
