import React , { useState, useEffect }from 'react';
import './Tempoimg.css';

const Tempoimg = ({ data,   setArlampopup,  arlampopup}) => {
  const [alarmAcknowledged1, setAlarmAcknowledged1] = useState(false); 
  const [alarmAcknowledged2, setAlarmAcknowledged2] = useState(false); 
  const [alarmAcknowledged3, setAlarmAcknowledged3] = useState(false); 
  //console.log(data);
  useEffect(() => {
    let newAlarms = [];
  
    Object.entries(data).forEach(([uid, sensor]) => {
      if (uid === '00.00000529DD39') {  // DI1
          //console.log('Sensor data:', sensor.data[0][0]);
          if (sensor.data[0][0] !== "0.000" && !alarmAcknowledged1) {
            setAlarmAcknowledged1(true);
            newAlarms.push(<span style={{ color: 'orange' }}> Trox Lüftungsanlage Störung</span>);

          } 
          if (sensor.data[0][0] === "0.000" && alarmAcknowledged1) {
            setAlarmAcknowledged1(false);  
          
          }

        }
      
  
      if (uid === '00.00000529DBA0') {  // DI2
        //console.log('Sensor data:', sensor.data[0][0]);
          if (sensor.data[0][0] !== "1.000" && !alarmAcknowledged2) {
            setAlarmAcknowledged2(true);
            newAlarms.push(<span style={{ color: 'orange' }}> Wolf Lüftungsanlage Störung</span>);
      
          } 
          if (sensor.data[0][0] === "1.000" && alarmAcknowledged2) {
            setAlarmAcknowledged2(false);
            
    
          }
        }
   
      if (uid === '00.00000529DD47') {  // DI3
        //console.log('Sensor data:', sensor.data[0][0]);
          if (sensor.data[0][0] !== "1.000" && !alarmAcknowledged3) {
            setAlarmAcknowledged3(true);
            newAlarms.push(<span style={{ color: 'orange' }}> Weger Lüftungsanlage Störung</span>);
          } 
          if (sensor.data[0][0] === "1.000" && alarmAcknowledged3) {
            setAlarmAcknowledged3(false);

          };
       }
    });
    if (newAlarms.length > 0) {
      console.log('New alarms:', newAlarms);
      setArlampopup(prev => [...prev, ...newAlarms.filter(alarm => !prev.includes(alarm))]);
    }
    //console.log("test");
  }, [data]);
  return (
    <div style={{ backgroundColor: '#fffff', borderRadius: '20px', padding: '20px' }}>
      <div style={{ position: 'relative', minWidth: 480, maxWidth: 2000 }}>
        <img
          src="/Tecomon471671/Tecomon471671_GR_f2.png"
          style={{ width: '100%', height: 'auto', minWidth: 480 }}
          alt="Grundriss"
        />

        {Object.entries(data).map(([uid, sensor]) => (
        <>
            {sensor.unitNames[0].startsWith('Temperatur') && (
            <>
                <div className={`imgbox feuchtbox-${uid.replace(/\./g, '')}`}>
                {parseFloat(sensor.data[1][0]).toFixed(1)} {sensor.units[1]}
                </div>
                <div className={`imgbox tempbox-${uid.replace(/\./g, '')}`}>
                {parseFloat(sensor.data[0][0]).toFixed(1)} {sensor.units[0]}
                </div>
            </>
            )}
            {sensor.unitNames[0].startsWith('AI 0') && (
            <>                
                <div className={`imgbox notedbox0-${uid.replace(/\./g, '')}`}>                    

                {isNaN(sensor.data[0][0]) ? 'NaN' : (parseFloat(sensor.data[0][0]) * 2.5).toFixed(1)} {'%O2'}
                </div>
                <div className={`imgbox notedbox1-${uid.replace(/\./g, '')}`}>
                {isNaN(sensor.data[1][0]) ? 'NaN' : (parseFloat(sensor.data[1][0]) * 2.5).toFixed(1)} {'%O2'}
                </div>                   

            </>
            )}
            {sensor.unitNames[0].startsWith('DI 0') && (
            <>
            
            {isNaN(sensor.data[0][0]) ? 'NaN' : 
                <>
                {uid=== '00.00000529DD39' && (
                <div className={`imgbox notedbox0-${uid.replace(/\./g, '')}`}>
Trox Lüftungsanlage: 
                  {sensor.data[0][0] !== "0.000" ? <b style={{color:'red'}}> Störung</b> : <b style={{color:'green'}}> i.O</b>}
                </div>
                )}
                {uid=== '00.00000529DBA0' && (
                <div className={`imgbox notedbox0-${uid.replace(/\./g, '')}`}>
Wolf Lüftungsanlage: 
                  {sensor.data[0][0] !== "1.000" ? <b style={{color:'red'}}> {sensor.data[0][0]}Störung</b> : <b style={{color:'green'}}> i.O</b>}
                </div>) }
                {uid=== '00.00000529DD47' && (
                <div className={`imgbox notedbox0-${uid.replace(/\./g, '')}`}>
Weger Lüftungsanlage: 
                  {sensor.data[0][0] !== "1.000" ? <b style={{color:'red'}}> Störung</b> : <b style={{color:'green'}}> i.O</b>}
                </div>) }
                </>
                }               
            </>
            )}
            {sensor.unitNames[0].startsWith('Feuchtigkeit') && (
                <>
            <div className={`imgbox notedbox0-${uid.replace(/\./g, '')}`}>
                {parseFloat(sensor.data[1][0]).toFixed(1)} {sensor.units[1]}
            </div>
            <div className={`imgbox notedbox1-${uid.replace(/\./g, '')}`}>
                {parseFloat(sensor.data[0][0]).toFixed(1)} {sensor.units[0]}
             </div>
             </>
            )}
        </>
        ))}
      </div>
    </div>
  );
};

export default Tempoimg;

 
  /*
const Tempoimg = ({ data }) => {

console.log(data);
  
    return (
        <div  style={{ backgroundColor:'#3e7dd1e8', borderRadius: '20px', padding:'20px' }}>
            <div style={{ position: 'relative', minWidth: 480, maxWidth: 1457 }}>
                <img
                    src="/Tecomon471671_GR.png"
                    style={{ width: '100%', height: 'auto', minWidth: 480 }}
                    alt="Grundriss"
                />

            </div>
        </div>
    );
};

export default Tempoimg;
*/
