import React, { useEffect, useState, useRef, useMemo } from 'react';
import './Dashboard.css';
import './Dashboard_spezial.css';
import { jwtDecode } from 'jwt-decode';
import CardZone from './CardZone.js';
import CardDeviceTemp from './CardDeviceTemp.js';
import Tempoimg from './Tempoimg.js';

const Dashboard = ({ activateDashboard }) => {
  const [allDevices, setAllDevices] = useState([]);
  const [datas, setdatas] = useState({});
  const intervalRef = useRef(null);
  const [arlampopup, setArlampopup] = useState([]);
  const [datapopup, setdatapopup] = useState('');
  const quittierePopup = () => {
    setArlampopup([]);
  };

  const tokenCookie = (() => {
    const name = 'serviceToken=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let cookie of cookieArray) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  })();

  const token = jwtDecode(tokenCookie);

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenCookie}`,
    },
  };

  const fetchData = async () => {
    try {
      const gatewayTypesResponse = await fetch(`${process.env.REACT_APP_PORTAL_SERVICE}/gateways`, fetchOptions);
      if (!gatewayTypesResponse.ok) throw new Error(`HTTP error, status = ${gatewayTypesResponse.status}`);
      const gatewayTypesData = await gatewayTypesResponse.json();
      const gatewayTypes = gatewayTypesData.gateways;
  
      const uids = [];
      const names = [];
      const gatewayData = [];
  
      const fetchAllGateways = async (gatewayType) => {
        const response = await fetch(
          `${process.env[`REACT_APP_${gatewayType.toUpperCase()}_SERVICE`]}/gateways`,
          fetchOptions
        );
        if (!response.ok) throw new Error(`HTTP error, status = ${response.status}`);
        const data = await response.json();
        uids.push(...data.UIDs);
        names.push(...data.names);
        data.UIDs.forEach(() => gatewayData.push(gatewayType));
      };
  
      await Promise.all(gatewayTypes.map(fetchAllGateways));
  
      const allDevices = [];
      const fetchGatewayDevices = async (gateway) => {
        const options = { ...fetchOptions, method: 'POST' };
        const response = await fetch(
          `${process.env[`REACT_APP_${gateway.gatewayType.toUpperCase()}_SERVICE`]}/devices`,
          {
            ...options,
            body: JSON.stringify({ gatewayUID: gateway.gatewayUID }),
          }
        );
        if (!response.ok) throw new Error(`HTTP error, status = ${response.status}`);
        const data = await response.json();
        data.UIDs.forEach((uid, index) => {
          allDevices.push({
            gatewayType: gateway.gatewayType,
            deviceUID: uid,
            deviceName: data.names[index],
            deviceUnits: data.units[index],
            unitNames: data.unitNames[index],
            deviceZone: data.zones[index],
            classname: ' ',
          });
        });
      };
  
      const gateways = uids.map((uid, index) => ({
        gatewayType: gatewayData[index],
        gatewayUID: uid,
        gatewayName: names[index],
      }));
      await Promise.all(gateways.map(fetchGatewayDevices));
  
      const fetchDeviceData = async (device) => {
        const gateway = device.deviceUID.startsWith('HO') ? 'HOMEMATIC' : 'EFFY';
        try {
          const response = await fetch(
            `${process.env[`REACT_APP_${gateway}_SERVICE`]}/devicedata?count=1&uid=${device.deviceUID}`,
            fetchOptions
          );
          if (!response.ok) throw new Error(`HTTP error, status = ${response.status}`);
          const data = await response.json();
  
          return {
            deviceData: data.unitData,
            timestamp: data.timestamps[0],
          };
        } catch (error) {
          console.error(`Fehler beim Abrufen der Gerätedaten für ${device.deviceUID}:`, error);
          return null; // WICHTIG: Hier null zurückgeben
        }
      };
  
      const newDeviceDataMap = {};
  
      await Promise.all(
        allDevices.map(async (device) => {
          const result = await fetchDeviceData(device);
  
          if (result !== null) {
            // Nur wenn das Fetch erfolgreich war, wird die neue Datenstruktur übernommen
            newDeviceDataMap[device.deviceUID] = {
              name: device.deviceName,
              uid: device.deviceUID,
              unitNames: device.unitNames,
              units: device.deviceUnits,
              zone: device.deviceZone,
              data: result.deviceData,
              date: result.timestamp,
              classname: device.classname,
            };
          }
        })
      );
  
      // **Erst hier wird setdatas() aufgerufen, wenn ALLE Daten erfolgreich abgerufen wurden**
      if (Object.keys(newDeviceDataMap).length > 0) {
        setdatas(newDeviceDataMap);
      } else {
        console.warn("⚠ Keine neuen Daten empfangen, alte Daten bleiben erhalten.");
      }
  
      setAllDevices(allDevices);
      setdatapopup('');
    } catch (err) {
      setdatapopup('Fehler bei API-Aufrufen. Seite ggf. neu laden!');
      console.error('Fehler bei den API-Aufrufen:', err);

    }
  };

  useEffect(() => {
    fetchData();
    intervalRef.current = setInterval(fetchData, 60000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const zonesWithDevices = useMemo(() => {
    return Object.entries(
      allDevices.reduce((acc, device) => {
        if (device.deviceZone) {
          if (!acc[device.deviceZone]) acc[device.deviceZone] = [];
          acc[device.deviceZone].push(device);
        }
        return acc;
      }, {})
    );
  }, [allDevices]);

  if (!activateDashboard) return null;

  return (
    <div className="Dashboard">
       {(arlampopup.length !== 0 || datapopup !== '')&& (
        <div className="popup-overlay">
          <div className="popup-content">
          {arlampopup.map((alarm, index) => (
            <div key={index}>{alarm}</div>
          ))}
          {datapopup ? (
            <div>
              <div>{datapopup}</div>
              <button className='popupbtn popupbtn1 ' onClick={() => window.location.reload()}>🔄 Seite neuladen</button>
            </div>
          ):(
            <p>
            <button className='popupbtn popupbtn2 ' onClick={quittierePopup}>schließen</button>
            </p>
          )}
         
          </div>
        </div>
      )}
      <h2>Dashboard</h2>
      <h3>Übersicht</h3>
      <div className="GatewaysDash">
        <Tempoimg data={datas}  setArlampopup={setArlampopup} arlampopup={arlampopup}/>
      </div>
      <h3>Zonen</h3>
      <div className="GatewaysDash">
        {zonesWithDevices.map(([zone, devices], index) => (
          <CardZone
            key={index}
            token={tokenCookie}
            name={zone}
            devices={devices}
            datas={datas}
            setArlampopup={setArlampopup}
            arlampopup={arlampopup}
          />
        ))}
      </div>
      <h3>Devices</h3>
      <div className="GatewaysDash">
        {allDevices.map((device, index) => {
          
          //Sonderwunsch explizit Shelly 3 ausblenden weil verwirrend?
          return device.deviceUID !== 'FF.FCE8C0DBCBF0' && ( 
          <CardDeviceTemp
            key={index}
            token={tokenCookie}
            name={device.deviceName}
            zone={device.deviceZone}
            uid={device.deviceUID}
            unitNames={device.unitNames}
            units={device.deviceUnits}
          />
          )
        })}
      </div>
    </div>
  );
};

export default Dashboard;
